import "./App.css";
import React, { useState } from "react";
import { Col, Menu, Row } from "antd";
import { Carousel } from "antd";
import logo from "./Images/Sabrina logo.png";
import fi1 from "./Images/curelty free.png";
import fi2 from "./Images/best value.png";
import img1 from "./Images/image1.png";
import img2 from "./Images/image2.png";
import img3 from "./Images/image3.png";
import img4 from "./Images/image4.png";
import img5 from "./Images/image5.png";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

const items = [
  {
    label: (
      <a href="#lashes" rel="noopener noreferrer">
        Lashes
      </a>
    ),
    key: "lashes",
  },
  {
    label: (
      <a href="#aboutus" rel="noopener noreferrer">
        About Us
      </a>
    ),
    key: "aboutus",
  },

  {
    label: (
      <a href="#contact" rel="noopener noreferrer">
        Contact
      </a>
    ),
    key: "contact",
  },
];

function App() {
  const [current, setCurrent] = useState("lashes");
  const onClick = (e) => {
    console.log("click ", e);
    setCurrent(e.key);
  };
  return (
    <div className="App">
      <div className="alert1">COMING SOON</div>
      <div id="lashes">
        <Row className="header" align={"middle"}>
          <Col xs={6} md={4}>
            <img src={logo} width={"100%"} alt="logo"></img>
          </Col>

          <Col xs={18} md={20}>
            <Row justify={"end"}>
              <Menu
                onClick={onClick}
                selectedKeys={[current]}
                mode="horizontal"
                items={items}
              />
            </Row>
          </Col>
        </Row>
        <div className="header-img">
          <Row>
            <Col span={12} align="middle">
              <div className="t1">Lashes by Sabrina</div>
              <div className="t2">IT'S MAGIC</div>
              <button id="comming-soon-btn" className="comming-soon-btn">COMING SOON</button>
            </Col>
          </Row>
        </div>
      </div>
      <div className="s1-img">
        <Row justify={"center"}>
          <Col span={22} lg={18} align="middle" className="s1-box">
            <div className="s1-img-t0">THE WOW EFFECT. ALL DAY LONG</div>
            <div className="s1-img-t1">COMMING SOON!</div>
            <div className="s1-img-t2">
              LET LASHES BY SABRINA SHOW YOU THE REAL YOU
            </div>
          </Col>
        </Row>
      </div>
      <div className="s2-img" id="aboutus">
        <Row>
          <Col span={12} align="middle" className="s2-box1">
            <Row justify={"end"}>
              <Col xs={18} xl={15} className="s2-img1">
                <div className="s2-img1-t0">GLAMOROUS LASHES</div>
                <button class="shopnow1-btn">SHOP NOW</button>
              </Col>
            </Row>
          </Col>
          <Col span={12} align="middle" className="s2-box2">
            <Row justify={"start"}>
              <Col xs={18} xl={15} className="s2-img2">
                <div className="s2-img2-t0">NATURAL EXTENSIONS</div>
                <button class=" shopnow2-btn">SHOP NOW</button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="s3">#LASHESBYSABRINA</div>

      <div className="slider">
        <Carousel arrows prevArrow={<LeftOutlined />} nextArrow={<RightOutlined />}>
          <div>
            <Row>
              <Col span={6}>
                <img src={img1} width={"100%"} alt="logo"></img>
              </Col>
              <Col span={6}>
                <img src={img2} width={"100%"} alt="logo"></img>
              </Col>
              <Col span={6}>
                <img src={img3} width={"100%"} alt="logo"></img>
              </Col>
              <Col span={6}>
                <img src={img4} width={"100%"} alt="logo"></img>
              </Col>
            </Row>
          </div>
          <div>
          <Row>
              <Col span={6}>
                <img src={img2} width={"100%"} alt="logo"></img>
              </Col>
              <Col span={6}>
                <img src={img3} width={"100%"} alt="logo"></img>
              </Col>
              <Col span={6}>
                <img src={img4} width={"100%"} alt="logo"></img>
              </Col>
              <Col span={6}>
                <img src={img5} width={"100%"} alt="logo"></img>
              </Col>
            </Row>
          </div>
          <div>
          <Row>
              <Col span={6}>
                <img src={img3} width={"100%"} alt="logo"></img>
              </Col>
              <Col span={6}>
                <img src={img4} width={"100%"} alt="logo"></img>
              </Col>
              <Col span={6}>
                <img src={img5} width={"100%"} alt="logo"></img>
              </Col>
              <Col span={6}>
                <img src={img1} width={"100%"} alt="logo"></img>
              </Col>
            </Row>
          </div>
          <div>
          <Row>
              <Col span={6}>
                <img src={img4} width={"100%"} alt="logo"></img>
              </Col>
              <Col span={6}>
                <img src={img5} width={"100%"} alt="logo"></img>
              </Col>
              <Col span={6}>
                <img src={img1} width={"100%"} alt="logo"></img>
              </Col>
              <Col span={6}>
                <img src={img2} width={"100%"} alt="logo"></img>
              </Col>
            </Row>
          </div>
          <div>
          <Row>
              <Col span={6}>
                <img src={img5} width={"100%"} alt="logo"></img>
              </Col>
              <Col span={6}>
                <img src={img1} width={"100%"} alt="logo"></img>
              </Col>
              <Col span={6}>
                <img src={img2} width={"100%"} alt="logo"></img>
              </Col>
              <Col span={6}>
                <img src={img3} width={"100%"} alt="logo"></img>
              </Col>
            </Row>
          </div>

        </Carousel>
      </div>
      <div className="footer" id="contact">
        <div className="line1"></div>
        <div className="center contact-us-txt">CONTACT US</div>
        <div className="line2 m30"></div>
        <Row justify={"center"}>
          <Col xs={20} md={14}>
            <Row justify={"center"} gutter={[16, 16]}>
              <Col xs={24} md={12}>
                <div className="footer-txt">
                  ADDRESS:
                  <br />
                  LASHES BY SABRINA, INC,
                  <br />
                  2140 S DUPONT HIGHWAY,
                  <br />
                  CAMDEN, DE 19934
                </div>
              </Col>
              <Col xs={0} md={6}></Col>
              <Col xs={24} md={6}>
                <div className="footer-txt">
                  EMAIL:
                  <br />
                  <a href="#mail" className="contact-link">
                    {" "}
                    info@lashesbysabrina.shop
                  </a>
                  <br />
                </div>
                <Row>
                  <Col xs={4} md={8} lg={10}>
                    <img src={fi1} alt="l1" width={"100%"}></img>
                  </Col>
                  <Col xs={4} md={8} lg={10}>
                    <img src={fi2} alt="l1" width={"100%"}></img>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="alert1">@2023 BY LASHES BY SABRINA</div>
    </div>
  );
}

export default App;
